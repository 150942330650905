import React, { useState } from "react";
import { Formik, Form, FormikErrors } from "formik";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { AddToast } from "react-toast-notifications";

import {
  UPDATE_SUPPORT_PROFESSIONAL,
  NewData,
  Variables,
} from "../../../../graphql/mutations/updateSupportProfessional";
import {
  SupportProfessional,
  EmploymentExperience,
} from "../../../../types/supportProfessional";
import {
  InputField as Input,
  DropdownGroup,
  Checkbox,
} from "../../../layout/forms";
import { Button } from "../../../layout/Button";
import { Icon } from "../../../layout/Icons";
import { useLogger } from "../../../../hooks/useLogger";

export interface FormValues {
  title: string;
  company: string;
  startDateMonth: string;
  startDateYear: string;
  endDateMonth: string;
  endDateYear: string;
  volunteer: boolean;
  present: boolean;
  delete?: boolean;
}

export interface EmploymentExperienceFormProps {
  supportProfessional: SupportProfessional;
  add?: AddToast;
  open?: boolean;
  initialValues?: FormValues;
  id?: string;
  reset?: () => void;
}

export const EmploymentExperienceForm = ({
  supportProfessional,
  add,
  initialValues = {
    title: "",
    company: "",
    present: false,
    volunteer: false,
    startDateMonth: "1",
    startDateYear: moment().format("YYYY"),
    endDateMonth: "1",
    endDateYear: moment().format("YYYY")
  },
  open,
  id,
  reset,
}: EmploymentExperienceFormProps) => {
  const { log } = useLogger();
  const [updateSupportProfessional] = useMutation<NewData, Variables>(
    UPDATE_SUPPORT_PROFESSIONAL
  );
  const [addingExperience, setAddingExperience] = useState(!!open);

  const handleMutation = (
    variables: {
      id: string;
      employmentExperience: EmploymentExperience[];
    },
    optimisticResponse: {
      __typename: string;
      updateSupportProfessional: SupportProfessional;
    },
    action: "creating" | "deleting"
  ) => {
    updateSupportProfessional({
      variables,
      optimisticResponse,
    })
      .then((res) => res)
      .catch((err) => {
        log("error", err);
        if (add) {
          add(`There was an error ${action} your Employment Experience`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleSubmit = (values: any, formikApi: any) => {
    const {
      startDateMonth,
      startDateYear,
      endDateMonth,
      endDateYear,
      title,
      company,
      volunteer,
      present,
    } = values;

    const startDate = moment([startDateYear, startDateMonth]).format(
      "YYYY-MM-DD"
    );
    const endDate = present
      ? moment()
          .subtract(1, "day")
          .format("YYYY-MM-DD")
      : moment([endDateYear, endDateMonth]).format("YYYY-MM-DD");

    const employmentExperience = supportProfessional.employmentExperience.map(
      (e) => {
        return e.id === id
          ? {
              __typename: "EmploymentExperience",
              id,
              startDate,
              endDate,
              title,
              company,
              volunteer,
              present,
            }
          : e;
      }
    );

    if (!id) {
      employmentExperience.push({
        __typename: "EmploymentExperience",
        id: "",
        startDate,
        endDate,
        title,
        company,
        volunteer,
        present,
      });
    }

    const variables = {
      id: supportProfessional.id,
      employmentExperience: simplifyInput(employmentExperience),
    };

    const optimisticResponse = {
      __typename: "Mutation",
      updateSupportProfessional: {
        ...supportProfessional,
        employmentExperience,
      },
    };

    setAddingExperience(false);
    formikApi.resetForm();
    if (reset) reset();

    handleMutation(variables, optimisticResponse, "creating");
  };

  const handleDelete = (id: string) => {
    const employmentExperience = supportProfessional.employmentExperience.map(
      (e) => {
        return e.id === id ? { ...e, delete: true } : e;
      }
    );

    const variables = {
      id: supportProfessional.id,
      employmentExperience: simplifyInput(employmentExperience),
    };

    const optimisticResponse = {
      __typename: "Mutation",
      updateSupportProfessional: {
        ...supportProfessional,
        employmentExperience: employmentExperience.filter((e) => e.id !== id),
      },
    };

    handleMutation(variables, optimisticResponse, "deleting");
  };

  const monthOptions = Array.from(Array(12).keys()).map((num) => {
    return {
      text: moment()
        .month(num)
        .format("MMMM"),
      value: num,
    };
  });

  const yearOptions = Array.from(Array(moment().year() - 1980 + 1).keys())
    .reverse()
    .map((num) => {
      const result = num + 1980;
      return { text: `${result}`, value: `${result}` };
    });

  return (
    <div className="mt-2">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: FormikErrors<FormValues> = {};

          if (!values.title) {
            errors.title = "Required";
          }
          if (!values.company) {
            errors.company = "Required";
          }
          if (!values.startDateMonth) {
            errors.startDateMonth = "Required";
          }
          if (!values.startDateYear) {
            errors.startDateYear = "Required";
          }
          if (
            moment([values.startDateYear, values.startDateMonth]).isAfter(
              moment([values.endDateYear, values.endDateMonth])
            ) &&
            !values.present
          ) {
            const errorMessage = "Start Date cannot be after End Date";
            errors.startDateMonth = errorMessage;
            errors.startDateYear = errorMessage;
          }
          if (
            moment([values.startDateYear, values.startDateMonth]).isAfter(
              moment()
            )
          ) {
            const errorMessage = "Start Date cannot be in the future";
            errors.startDateMonth = errorMessage;
            errors.startDateYear = errorMessage;
          }
          if (
            moment([values.endDateYear, values.endDateMonth]).isAfter(
              moment()
            ) &&
            !values.present
          ) {
            const errorMessage =
              "End Date cannot be in the future. Please Set this as your present position instead.";
            errors.endDateMonth = errorMessage;
            errors.endDateYear = errorMessage;
          }

          return errors;
        }}
      >
        {(props) => {
          const { errors, touched, values, resetForm } = props;

          return addingExperience ? (
            <>
              <Form data-testid="employment-experience-form" className="">
                <div className="mt-10 sm:mt-0">
                  <div className="mt-5 md:mt-0">
                    <div className="px-1 py-5 bg-white">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <Input
                            name="title"
                            label="Job Title"
                            className="mb-4"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <Input
                            name="company"
                            ariaLabel="Company"
                            className="mb-4"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <DropdownGroup
                            label="Start Date"
                            values={[
                              {
                                name: "startDateMonth",
                                options: monthOptions,
                              },
                              {
                                name: "startDateYear",
                                options: yearOptions,
                              },
                            ]}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        {!values.present && (
                          <div className="col-span-6 sm:col-span-3">
                            <DropdownGroup
                              label="End Date"
                              values={[
                                {
                                  name: "endDateMonth",
                                  options: monthOptions,
                                },
                                {
                                  name: "endDateYear",
                                  options: yearOptions,
                                },
                              ]}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}

                        <div className="col-span-6">
                          <Checkbox
                            className="mb-4"
                            name="present"
                            label="Presently working here"
                            ariaLabel="Are you presently working here?"
                          />
                        </div>
                        <div className="col-span-6">
                          <Checkbox
                            className="mb-4"
                            name="volunteer"
                            label="Was/is this a volunteer position?"
                            ariaLabel="Was/is this a volunteer position?"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 text-right sm:px-6">
                      <div className="flex justify-end items-center">
                        <Icon
                          className="w-3 h-3 mr-4 text-gray-600 cursor-pointer"
                          name="close"
                          onClick={() => {
                            resetForm();
                            setAddingExperience(false);
                            if (reset) reset();
                          }}
                        />
                        {id && (
                          <Button
                            type="button"
                            onClick={() => handleDelete(id)}
                            className="mr-3"
                            color="red"
                            outline
                          >
                            Delete
                          </Button>
                        )}
                        <Button type="submit">Save</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          ) : (
            <Button
              className="flex items-center"
              onClick={() => setAddingExperience(true)}
            >
              <Icon className="h-5 w-5 mr-4" name="addCircle" />
              Add
            </Button>
          );
        }}
      </Formik>
    </div>
  );
};

export const simplifyInput = (attrs: any | any[]) => {
  if (Array.isArray(attrs)) {
    return attrs.map((a) => {
      const { __typename, ...rest } = a;
      return rest;
    });
  } else {
    const { __typename, ...rest } = attrs;
    return rest;
  }
};
